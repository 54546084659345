import { ref } from 'vue';

export function useErrorHandling() {
    const formErrors = ref({});

    function handleFormErrors(newErrors) {
        if (newErrors && Object.keys(newErrors).length > 0) {
            for (const [key, value] of Object.entries(newErrors)) {
                let finalKey = key;
                if (key.includes('.')) {
                    let parts = key.split('.');
                    finalKey = `${parts[0]}[${parts[1]}]`;
                }
                formErrors.value[finalKey] = value.join('<br>');
            }
        }
    }

    function cleanErrors() {
        formErrors.value = {};
    }

    return {
        formErrors,
        handleFormErrors,
        cleanErrors
    };
}
