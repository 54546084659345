export const gtm = {
    push(data) {
        if (process.client) {

            const config = useRuntimeConfig();
            const id = config.public.gtm?.id;

            if (!id) {
                return;
            }

            useGtm().push(data);
            //console.log('GTM: ' + JSON.stringify(data));
        }
    },

    event(event, value) {
        this.push({
            "event": 'ga-event',
            "eventCategory": event,
            "eventAction": value
        });
    },

    banner(code, name) {
        this.push({
            "event": 'ga-event',
            "eventCategory": 'Banner',
            "eventAction": 'click',
            "eventLabel": name,
            "eventValue": code
        });
    }
};
