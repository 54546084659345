import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useErrorStore = defineStore('error_store', () => {
    const errors = ref({});


    function handleFormErrors(newErrors) {
        if (newErrors && Object.keys(newErrors).length > 0) {
            for (const [key, value] of Object.entries(newErrors)) {
                errors.value[key] = value.join('<br>');
            }
        }
    }

    function has(id) {
        return errors.value[id]?.length > 0;
    }

    function get(id) {
        return errors.value[id];
    }

    function remove(id) {
        if (!has(id)) {
            return;
        }
        delete errors.value[id];
    }

    function cleanErrors() {
        errors.value = {};
    }

    return {
        handleFormErrors,
        cleanErrors,
        has,
        get,
        remove
    };
});
