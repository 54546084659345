import {useClientFetch} from "./useClientFetch.js";

export async function getFromClient(url, opts = []) {
    const response = await useClientFetch(url, opts);

    if (!response) {
        throw new Error(`Error in ${url}`);
    }

    return response.data;
}
