import {getFromServer} from "./getFromServer.js";
import {getFromClient} from "./getFromClient.js";

export async function getFromAny(url, opts = []) {
    if (process.server) {
        return getFromServer(url, opts);
    }

    return getFromClient(url, opts);
}
