import {useServerFetch} from "./useServerFetch.js";

export async function getFromServer(url, opts = []) {
    const { data, error, pending, response } =  await useServerFetch(url, opts);

    if (error.value) {
        showError({
            statusCode: 503,
        });
    }

    return data.value?.data;
}
