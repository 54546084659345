import { default as _91_46_46_46slug_93GBL0NGEPVDMeta } from "/usr/src/nuxtapp/pages/[...slug].vue?macro=true";
import { default as contact_45ush1OPrrqyRpMeta } from "/usr/src/nuxtapp/pages/contact-us.vue?macro=true";
import { default as faqGJMKCsTbsaMeta } from "/usr/src/nuxtapp/pages/faq.vue?macro=true";
import { default as index9D2IrMu3ubMeta } from "/usr/src/nuxtapp/pages/index.vue?macro=true";
import { default as loginuxquRycQutMeta } from "/usr/src/nuxtapp/pages/login.vue?macro=true";
import { default as registerQczl10k7cRMeta } from "/usr/src/nuxtapp/pages/register.vue?macro=true";
import { default as searchLYII3OUDR7Meta } from "/usr/src/nuxtapp/pages/search.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___ru",
    path: "/ru/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93GBL0NGEPVDMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/[...slug].vue")
  },
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___es",
    path: "/es/contactanos",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___fr",
    path: "/fr/contactez-nous",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___ru",
    path: "/ru/kontakt",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___it",
    path: "/it/contattaci",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___de",
    path: "/de/kontaktieren-sie-uns",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "contact-us___pl",
    path: "/pl/kontakt",
    component: () => import("/usr/src/nuxtapp/pages/contact-us.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___it",
    path: "/it/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "faq___pl",
    path: "/pl/faq",
    component: () => import("/usr/src/nuxtapp/pages/faq.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: index9D2IrMu3ubMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/iniciar-sesion",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/connexion",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___ru",
    path: "/ru/vkhod",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/accedi",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/anmeldung",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/logowanie",
    meta: loginuxquRycQutMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/login.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___es",
    path: "/es/registro",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/inscription",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___ru",
    path: "/ru/registratsiya",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/registrazione",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___de",
    path: "/de/registrierung",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "register___pl",
    path: "/pl/rejestracja",
    meta: registerQczl10k7cRMeta || {},
    component: () => import("/usr/src/nuxtapp/pages/register.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  },
  {
    name: "search___pl",
    path: "/pl/search",
    meta: searchLYII3OUDR7Meta || {},
    component: () => import("/usr/src/nuxtapp/pages/search.vue")
  }
]