<template>
    <BaseField :col="col" :label="label" :id="id" :form-group="formGroup" :required="required" :show-error-message="showErrorMessage">
        <input
            ref="baseInput"
            :type="type"
            :min="min"
            :max="max"
            class="form-control"
            :class="{ 'is-invalid': hasError, [additionalClass]: additionalClass}"
            :id="id"
            :placeholder="placeholder ? $t(placeholder) : ''"
            :value="model"
            :required="required"
            :disabled="disabled"
            @input="emitUpdate($event.target.value)"
        >
    </BaseField>
</template>

<script>
import {defineComponent} from 'vue';
import BaseField from './BaseField.vue';

export default defineComponent({
    components: {
        BaseField
    },
    extends: BaseField,
    props: {
        additionalClass: String,
        placeholder: String,
        type: {
            type: String,
            default: 'text'
        },
        disabled: Boolean,
        min: Number,
        max: Number,
        model: [String, Number]
    },
    methods: {
        focus() {
            this.$refs.baseInput.focus();
        }
    }
});
</script>
