import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useClientFetch } from "../composables/useClientFetch.js";
import {getFromServer} from "../composables/getFromServer.js";
import eventBus from "../eventBus.js";
import {useMenuStore} from './MenuStore.js';
import {getFromAny} from "../composables/getFromAny.js";
import {useHomepageStore} from "./HomepageStore.js";
import {usePageMetadata} from "../composables/useMeta.js";
import {useSettingsStore} from "./SettingsStore.js";

export const useLocaleStore = defineStore('locale', () => {
    const languages = ref([]);
    const locale = ref('en-GB');
    const prefix = ref('en');
    const currencies = ref([]);
    const currency = ref([]);
    const ship_countries = ref([]);
    const ship_country = ref([]);

     function initData(data) {
        languages.value = data.languages;
        locale.value = data.locale;
        prefix.value = data.prefix;
        currencies.value = data.currencies;
        currency.value = data.currency;
        ship_countries.value = data.ship_countries;
        ship_country.value = data.ship_country;
    }

    async function fetchLanguages() {
        const data = await getFromServer('locale');
        initData(data);
    }

    function getLanguage() {
        return prefix.value || 'en';
    }

    function getLanguageInstance() {
        return languages.value.find((language) => language.prefix === prefix.value);
    }

    async function changeLanguage(language) {
        if (language === prefix.value) {
            return;
        }
        prefix.value = language;

        const data = await getFromAny('/locale/language', {
            method: 'POST',
            body: {language, path: useRoute().path}
        });
        this.initData(data.locale);
        useMenuStore().initData(data.menu);
        useHomepageStore().setHomepageData(data.homepage_data);
        useSettingsStore().components = data.components;
        if (url('') === useRoute().path) {
            usePageMetadata().setSchema(data.homepage_data);
            usePageMetadata().setMetaData(data.homepage_data);
        }

        useHead({
            htmlAttrs: {
                lang: language
            },
        });

        eventBus.languageChanged = language;
        // await useSettingsStore().fetchTranslatableSettings();
    }

    async function changeCountry(code) {
        return await useClientFetch('/ship-to-country', {
            method: 'POST',
            body: {code}
        });
    }

    function url(path) {
        let currentLanguage = getLanguage();

        let url = '/' + currentLanguage + '/' + path;

        if (!url.endsWith('/')) {
            url += '/';
        }

        return url;
    }
    function changeCurrency(inputCurrency) {
        return useClientFetch(`currency/change/?currency=${inputCurrency.id}`, {
            method: 'POST',
        }).then((response) => {
            window.location.reload();
        });
    }

    return {
        languages,
        locale,
        prefix,
        currencies,
        currency,
        ship_countries,
        ship_country,
        initData,
        fetchLanguages,
        changeCurrency,
        changeLanguage,
        changeCountry,
        getLanguage,
        getLanguageInstance,
        url,
    };
});
