import {defineStore} from 'pinia';
import {ref} from 'vue';
import {useSettingsStore} from "./SettingsStore.js";
import {useModal} from "./ModalStore.js";
import PromoModal from "../components/Modals/FirstOrderPromo/PromoModal.vue";

export const useNavigationStore = defineStore('navigation', () => {
    const pageCount = ref(0);
    const hasScrolledHalf = ref(false);

    const getPageCount = () => pageCount.value;
    const incrementPageCount = () => {
        pageCount.value++;
        if (pageCount.value > 1 && shouldShowPromoModal()) {
            setTimeout(() => useModal().openModal('PromoModal', PromoModal), 1000);
        }
    };

    const setScrolledHalf = () => hasScrolledHalf.value = true;

    const shouldShowPromoModal = () => pageCount.value > 1 && hasScrolledHalf.value && useSettingsStore().hasAccessToComponent('promo');

    return {
        getPageCount,
        incrementPageCount,
        setScrolledHalf,
        hasScrolledHalf,
        shouldShowPromoModal
    };
});
