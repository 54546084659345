import {useClientFetch} from "../composables/useClientFetch.js";
import {getFromServer} from "../composables/getFromServer.js";

export default {
    subscribeToNewsletter(email) {
        return useClientFetch('user/newsletter/', {
            method: 'POST',
            body: {email}
        });
    },
    promo(email) {
        return useClientFetch('promo', {
            method: 'POST',
            body: {email}
        });
    },
    async forgotPassword(email) {
        return await useClientFetch('forgot-password/', {
            method: 'POST',
            body: {email}
        });
    },
    async contact(body) {
        return await useClientFetch('contact', {
            method: 'POST',
            body
        });
    },
    async accountTypes() {
        return await getFromServer('user/account-types');
    },
    async login(body) {
        // data.redirect = useUrlComposable().get('redirect');
        return await useClientFetch('login', {
            method: 'POST',
            body
        });
    },
    async register(body) {
        // data.redirect = useUrlComposable().get('redirect');
        return await useClientFetch('user/register', {
            method: 'POST',
            body
        });
    },
};
