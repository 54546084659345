<template>
    <minimal>
        <p>{{$t('Service Unavailable')}}</p>
    </minimal>
</template>

<script setup>
import Minimal from "./minimal.vue";
const { t } = useI18n({
    useScope: 'local'
})
</script>

<i18n lang="json">
{
    "en": {
        "Service Unavailable": "Service Unavailable"
    },
    "ru": {
        "Service Unavailable": "Сервис недоступен"
    }
}
</i18n>
