import {useRequestEvent} from 'nuxt/app';

export default defineNuxtRouteMiddleware((to, from) => {
    const supportedLanguages = ['en', 'ru', 'fr', 'de', 'es', 'it', 'pl'];
    const pathSegments = to.fullPath.split('/');
    const currentLocale = pathSegments[1];

    if (currentLocale && supportedLanguages.includes(currentLocale)) {

    } else if (useRequestEvent().context.pageInfo?.data?.url) {
        const url = useRequestEvent().context.pageInfo.data.url;

        return navigateTo(url);
    }
});
