<template>
    <div v-if="col" :class="`col-${col} ${extraClass || ''}`">
        <div class="form-group" :class="extraClass">
            <label v-if="label" :for="id" class="form-label" :class="{required}">{{ $t(label) }}</label>
            <slot></slot>
        </div>
        <div v-if="hasError" class="invalid-feedback d-block">
            {{ errorMessage }}
        </div>
    </div>


    <template v-else>
        <div v-if="formGroup" class="form-group" :class="extraClass">
            <label v-if="label" :for="id" class="form-label" :class="{required}">{{ $t(label) }}</label>
            <slot></slot>
        </div>
        <template v-else>
            <slot></slot>
        </template>
        <div v-if="hasError && showErrorMessage" class="invalid-feedback d-block" v-html="errorMessage"></div>
    </template>
</template>


<script>
import {useErrorStore} from "../../stores/ErrorStore.js";

export default {
    props: {
        id: String,
        label: String,
        model: {},
        extraClass: String,
        col: {},
        required: {
            type: Boolean,
            default: true
        },
        formGroup: {
            type: Boolean,
            default: true
        },
        showErrorMessage: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        hasError() {
            return useErrorStore().has(this.id);
        },
        errorMessage() {
            return useErrorStore().get(this.id);
        },
    },

    methods: {
        emitUpdate(value) {
            useErrorStore().remove(this.id);
            this.$emit('update:model', value);
        }
    }
};
</script>
