<template>
    <div class="text-center">
        <Error503 v-if="error.statusCode === 503"/>

        <template v-else-if="error.statusCode === 404">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3"><span class="text-danger"></span> {{ $t('Page not found') }}</p>
        </template>
        <template v-else>
            <h1 class="display-1 fw-bold">{{ error.statusCode }}</h1>
            <p class="fs-3"><span class="text-danger"></span> {{ error.message }}</p>
        </template>
    </div>
</template>


<script setup>
import Error503 from "./errors/Error503.vue";

const { t } = useI18n();
const error = useError();

// No layouts for specific errors
if (error.value.statusCode !== 503) {
    setPageLayout('default');
} else {
    setPageLayout('none');
}

</script>

<script>
export default {
    mounted() {
        if (useError().value.statusCode === 404) {
            gtm.push({
                "event": 'ga-event',
                "eventCategory": '404',
                "eventAction": 'pageview',
                "eventLabel": 'NotFound',
                "eventValue": window.location.pathname
            });
        }
    },
};
</script>
