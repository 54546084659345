import { useFetch, useRequestHeaders } from "nuxt/app";
import {useLocaleStore} from "../stores/LocaleStore.js";

export function useServerFetch(url, opts = []) {
    const { secret } = useRuntimeConfig();
    const { $i18n } = useNuxtApp();
    const locale = $i18n?.locale.value;

    let headers = {
        'Authorization': 'Bearer ' + secret,
        'Accept-Language': locale,
        'Environment': 'nuxt',
        'Lang': locale,
        'X-Currency': useLocaleStore().currency?.unit_text
    };

    if (process.server) {
        headers = {
            ...headers,
            ...useRequestHeaders(['referer', 'cookie'])
        }
    }

    // Csrf header
    const token = useCookie('XSRF-TOKEN');
    if (token.value) {
        headers['X-XSRF-TOKEN'] = token;
    }

    // Add first slash if missing
    if (url.substring(0, 1) !== '/') {
        url = '/' + url;
    }

    return useFetch('/api/server' + url, {
        headers: {
            ...headers,
            ...opts?.headers
        },
        watch: false,
        credentials: 'include',
        ...opts
    })
}
