import { defineNuxtRouteMiddleware } from 'nuxt/app'
import {useNavigationStore} from "../stores/NavigationStore.js";

export default defineNuxtRouteMiddleware((to, from) => {
    const navigationStore = useNavigationStore();

    if (from.name) {
        navigationStore.incrementPageCount()
    }
})
