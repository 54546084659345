import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useHomepageStore = defineStore('homepage_store', () => {
    const blocks = ref([]);
    const mints = ref([]);
    const contents = ref([]);
    const banners = ref({});
    const categories = ref({});

    const setHomepageData = (homepageData) => {
        blocks.value = homepageData.page.children;
        mints.value = homepageData.mints;
        contents.value = homepageData.contents;
        banners.value = homepageData.banners;
        categories.value = homepageData.categories;
    }

    return {
        blocks,
        mints,
        contents,
        banners,
        categories,
        setHomepageData
    };
});
