import {defineStore} from 'pinia';
import {ref } from 'vue';
import {getFromServer} from "../composables/getFromServer.js";
import {useTranslatableData} from "../composables/useTranslatableData.js";

export const useSettingsStore = defineStore('settings', () => {
    const settings = ref([]);
    const translatableSettings = ref([]);
    const components = ref([]);
    const social = ref([]);
    const datalayer = ref([]);
    const promo = ref({});


    function initData(data) {
        settings.value = data.settings;
        components.value = data.components;
        translatableSettings.value = data.translatable;
        social.value = data.social;
        promo.value = data.promo;
    }

    async function fetchData() {
        settings.value = await getFromServer('settings');
        components.value = await getFromServer('settings/components');
        await fetchTranslatableSettings();
    }

    async function fetchTranslatableSettings()
    {
        translatableSettings.value = (await useTranslatableData('settings/translatable')).value;
    }


    function hasAccessToComponent(name, strict) {
        const component = getComponent(name);

        return (!component && !strict) || component?.state;
    }

    function get(key, def = '') {
        return settings.value[key] || def;
    }

    function translatable(key) {
        return translatableSettings.value[key];
    }

    function getComponent(name) {
        const [componentName, modif] = name.split('.');

        return components.value.find(
            (component) => component.type === 'component' && component.name === componentName && (!modif || component.modif === modif)
        )
    }

    function getController(name) {
        return components.value.find((component) => component.type === 'controller' && component.name === name);
    }

    return {
        settings,
        components,
        social,
        datalayer,
        translatableSettings,
        initData,
        get,
        translatable,
        getComponent,
        getController,
        fetchData,
        fetchTranslatableSettings,
        hasAccessToComponent,
        promo
    };
});
