<template>
    <div class="d-flex justify-content-center align-items-center vh-100" data-bs-theme="dark">
        <div class="wrapper error-container">
            <div class="">
                <div class="logo">
                    <img src="@img/logo-stonexbullion-w.svg" class="logo" alt="logo" />
                </div>
                <div class="wrapper_h">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.wrapper { background-color:rgb(33, 37, 41);margin: 0 auto; position: relative; width: 480px; display: inline-block; zoom: 1; vertical-align: middle; text-align: left; box-shadow: 0 4px 10px #6F6F6F; border-top: 10px solid #a6a9ac; }

.wrapper_h { font-size: 20px; line-height: 1.4; text-align: center; margin-bottom: 20px; }
.logo img { display: block; max-width: 100%; }
.logo {
    display: block;
    margin: 20px auto 50px;
    width: 320px;
    height: 40px;
}
</style>
