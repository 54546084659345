<script setup>
import {ref, watch} from 'vue';
import TextField from "../../Fields/TextField.vue";
import {useI18n} from "vue-i18n";

import {useSettingsStore} from "../../../stores/SettingsStore.js";
import {useErrorHandling} from "../../../composables/useErrorHandling.js";


const { formErrors } = useErrorHandling();

const props = defineProps({
    amount: String,
});

const { t } = useI18n();

const emit = defineEmits(['update-email']);
const email = ref('');

const agreementText = t('promo.form.terms', {
    policy: `<a class="text-decoration-underline" href="${useSettingsStore().translatable('privacy_policy_url')}">${t('Privacy Notice')}</a>`
});

watch(email, (newEmail) => {
    emit('update-email', newEmail);
});
</script>

<template>
    <h1 class="promo-title h1-sm fw-bold mb-4 text-center">
        {{ $t('promo.form.title', {amount: $formatter.price(amount, null, 0)}) }}
    </h1>

    <p class="promo-info-text mb-4 text-start">
        {{ $t('promo.form.text', {amount: $formatter.price(amount, null, 0)}) }}
    </p>

    <div class="form-group mb-3 w-100 text-start">
        <TextField
            id="email"
            additional-class="w-100"
            placeholder="promo.form.input_placeholder"
            :required="true"
            :model="email"
            @update:model="email = $event">
        </TextField>
    </div>

    <small class="text-muted d-block mb-4 text-start" v-html="agreementText"/>
</template>


<style scoped>
.promo-info-text {
    font-size: 14px !important;
}

.form-group {
    margin-bottom: 1rem;
}

button {
    padding: 0.75rem;
    font-size: 1.1rem;
    font-weight: bold;
}

small {
    font-size: 11px !important;
}
</style>
